import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import { FaqControllerService } from '@/__generated__/FrontApi';
import BgTop from '@/assets/img/tmp/bg_faq.jpg';
import TopImg from '@/assets/img/tmp/faq_img1@2x.png';
import AccordionComponent, { AccordionItem } from '@/components/Accordion';
import BoardSearchBar from '@/components/BoardSearchBar';
import Button from '@/components/Button';
import ButtonMoreView from '@/components/ButtonMoreView';
import Container from '@/components/Container';
import Row from '@/components/Grid/Row';
import Image from '@/components/Image';
import NewsBoardSearchBar from '@/components/NewsBoardSearchBar';
import TagPagination from '@/components/Pagination/TagPagination';
import Section from '@/components/Section';
import { Tit } from '@/components/Titles';
import { BOARD_KIND_FAQ } from '@/helpers/BoardHelper';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { getCurrentPageInfo, PageInfo } from '@/helpers/PaginationHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { usePopupStore } from '@/stores/PopupStore';
import { Link, navigate, PageProps } from 'gatsby';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

interface FaqData {
  id: string;
  subject: string;
  contents: string;
  category: string;
}

const SectionTop = styled(Section)`
  margin-top: 96px;
  padding: 0;
  position: relative;

  .flex-wrap {
    display: flex;
    align-items: center;

    .col-img {
      width: 389px;
      position: relative;

      &::before,
      &::after {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
      }

      &::before {
        width: 400px;
        background: #fff url(${BgTop}) center bottom no-repeat;
        background-size: 100% auto;
        right: 100%;
      }

      &::after {
        width: 1271px;
        background: #f8f9fa;
        left: 249px;
      }

      ${Image} {
        position: relative;
        z-index: 1;
      }
    }
    .col-dec {
      position: relative;
      z-index: 1;
      width: calc(100% - 389px);
      padding-left: 91px;
    }
  }
  ${breakpoint(1160)} {
    .flex-wrap {
      .col-dec {
        padding-left: 7.84vw;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    margin-top: 72px;
    .flex-wrap {
      margin: 0 -20px;
      flex-wrap: wrap;
      .col-img,
      .col-dec {
        width: 100%;
      }
      .col-img {
        padding-left: 50px;

        &::before {
          width: 69.4vw;
          height: 69.4vw;
          background: #b2def1;
          border-radius: 100%;
          top: auto;
          bottom: 0;
          right: auto;
          left: 0;
          transform: translate(-50%, 0);
        }
      }
      .col-dec {
        padding: 48px 20px;
        background: #f8f9fa;
      }
    }
  }
`;
const PanelContainer = styled.div`
  .UiTabPanel {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;

    & > li {
      padding: 0 12px;
      width: 20%;
      margin-bottom: 24px;
    }

    ${breakpoint(`tablet`)} {
      margin: 0 -8px;

      & > li {
        padding: 0 8px;
        width: 33.33%;
        margin-bottom: 16px;
      }
    }
  }
`;

const TabButton = styled(Button)<{ active: boolean }>`
  white-space: nowrap;
  border-color: ${(props) => (props.active ? `#1cabe2` : `#b7b8ba`)};
  color: ${(props) => (props.active ? `#1cabe2` : `#828385`)};
  font-size: 16px;
  ${breakpoint(640)} {
    font-size: 14px;
  }
`;
const TabContents = styled.div``;
const TabContainer = styled.div``;
const SectionFaq = styled(Section)`
  padding-top: 64px;
  ${Row} {
    justify-content: flex-end;
  }
  ${breakpoint(`tablet`)} {
    padding-top: 48px;

    ${Row} {
      margin-bottom: 24px;
    }
  }
  ${breakpoint(`mobile`)} {
    ${BoardSearchBar} {
      width: 100%;
    }
  }
`;

const HashButton = styled(Button)<{ active?: boolean }>`
  background: #fff;
  border-color: ${(props) => (props.active ? `#1cabe2` : `#b7b8ba`)};
  color: ${(props) => (props.active ? `#1cabe2` : `#828385`)};
  font-weight: normal;
  ${breakpoint(800)} {
    height: 36px;
    line-height: 34px;
    border-radius: 36px;
    padding: 0 12px;
  }
  ${breakpoint(640)} {
    font-size: 14px;
  }
`;
const TagBox = styled.div`
  background: #f8f9fa;
  padding: 24px;
  padding-bottom: 12px;
  a {
    display: inline-block;
    white-space: nowrap;
    margin-right: 12px;
    margin-bottom: 12px;
    ${breakpoint(`tablet`)} {
      margin-right: 8px;
      margin-bottom: 16px;
    }
  }

  ${breakpoint(`tablet`)} {
    margin: 0 -20px;
    padding: 24px 20px;
    padding-bottom: 8px;
  }
`;

const NavigationButton = styled.div`
  padding: 24px 32px 24px 24px;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  position: relative;

  dl {
    display: flex;
    align-items: center;

    dt {
      padding-right: 40px;
      font-size: 16px;
      min-width: 105px;
    }
    dd {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .sm-cg {
        display: inline-block;
        vertical-align: top;
        line-height: 29px;
        margin-right: 8px;
      }
    }
  }
  ${breakpoint(640)} {
    padding: 20px 32px 20px 12px;
    dl {
      dt {
        font-size: 14px;
        padding-right: 12px;
        min-width: auto;
        white-space: nowrap;
      }
      dd {
        font-size: 14px;
        .sm-cg {
          font-size: 14px;
          line-height: normal;
        }
      }
    }
  }
`;
const AccordionBody = styled.div`
  padding: 24px;
  font-size: 16px;
  color: #2d2926;
  white-space: pre-line;
  ${breakpoint(`mobile`)} {
    font-size: 14px;
    padding: 16px 0;
  }
  // blut css 보정
  ul,
  ol,
  li {
    list-style: inherit;
    list-style-position: inside;
    list-style-type: inherit;
  }
  ol {
    list-style-type: demical;
  }
  ul {
    list-style-type: disc;
  }
  a {
    color: #1cabe2;
  }
`;

const AccordionContainer = styled(AccordionComponent)`
  margin-top: 32px;
  margin-bottom: 64px;
  .is-active {
    ${NavigationButton} {
      dl {
        dd {
          text-overflow: normal;
          white-space: normal;
        }
      }
    }
  }
  .sm-cg-title {
    color: #1cabe2;
  }
`;
const Content = styled.div``;
const NoData = styled.div`
  margin-top: 32px;
  border-top: 1px solid #2d2926;
  .dec {
    padding-top: 96px;
    line-height: 1.6;
    padding-bottom: 119px;
    text-align: center;
    display: block;
  }

  .question-wrap {
    padding: 96px 0;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 1920px;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
      background: #f8f9fa;
      z-index: -1;
    }

    dl {
      max-width: 684px;
      margin: 0 auto;
      display: flex;
      align-items: center;

      dt {
        width: calc(100% - 256px);
        text-align: center;
      }
      dd {
        width: 256px;

        ${ButtonMoreView} {
          width: 100%;
        }
      }
    }
  }
  ${breakpoint(`tablet`)} {
    margin-top: 24px;
    .dec {
      padding: 72px;
    }

    .question-wrap {
      padding: 45px 0;

      dl {
        flex-wrap: wrap;
        max-width: 100%;

        dt,
        dd {
          width: 100%;
          text-align: center;
        }
        dd {
          margin-top: 16px;

          ${ButtonMoreView} {
            white-space: nowrap;
            width: 100%;
            max-width: 180px;
          }
        }
      }
    }
  }
`;

const Faq: React.FC<PageProps> = ({ location }) => {
  // 키워드 탭 강제 렌더링 실행용 state
  const [loaded, setLoaded] = useState(false);

  const popupStore = usePopupStore();

  const qs = queryString.parse(location.search);
  // 검색중일때 현재 페이지 데이터
  const searchCurrentPage = qs.currentPage ? Number(qs.currentPage) : 1;
  const keyword = qs.keyword as string;
  const category = qs.category as string;
  const optData2 = qs.optData2 as string;

  // 기본데이터는 빈배열 (조건에 따라 로드되기 때문)
  const [nodes, setNodes] = useState<BoardVo[]>([]);
  const [currentPageInfo, setCurrentPageInfo] = useState<PageInfo>();

  const [cg, setCg] = useState([]);
  const [hash, setHash] = useState([]);

  const loadFaq = useCallback(async () => {
    try {
      const {
        totalCount,
        data,
      } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: [BOARD_KIND_FAQ],
        page: 1,
        pagePerCount: 6,
        faqOrderOption: 'Y',
      });

      // 페이지네이션 정보
      const faqTotalCount = totalCount as any;
      const pageInfo = getCurrentPageInfo({
        totalItemCount: faqTotalCount,
        baseUri: '/faq',
        pageGroupSize: 5,
        currentPage: 1,
        itemsPerPage: 6,
      });
      setCurrentPageInfo(pageInfo);

      // faq 목록
      const faq = data as any;
      setNodes(faq);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadData = useCallback(() => {
    FaqControllerService.faQcategoryListUsingGet()
      .then(({ data }) => {
        setCg(data);
      })
      .catch((e) => console.error(e));
    FaqControllerService.faqChildcategoryListUsingGet()
      .then(({ data }) => {
        setHash(data);
      })
      .catch((e) => console.error(e));
  }, []);

  // 동적 데이터 로드
  const loadPage = useCallback(
    async (
      targetPage: number,
      targetKeyword?: string,
      targetCategory?: string,
      targetHash?: string,
    ) => {
      const {
        resultCode,
        data: _data,
        totalCount,
      } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: [BOARD_KIND_FAQ],
        faqOrderOption: `Y`,
        keyword: targetKeyword,
        optData1: targetCategory,
        optData2: targetHash,
        page: targetPage,
        pagePerCount: 6,
      });

      const newData = _data as any;

      if (resultCode === `success`) {
        const boardPageInfo = getCurrentPageInfo({
          totalItemCount: totalCount || 0,
          currentPage: targetPage,
          pageGroupSize: 5,
          itemsPerPage: 6,
          baseUri: '/faq',
          keyword: targetKeyword,
          category: targetCategory,
          optData2: targetHash,
        });

        setNodes(newData);
        setCurrentPageInfo(boardPageInfo);
      }
    },
    [],
  );

  // 강제 렌더링 실행
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]);

  useEffect(() => {
    // keyword 변경되면 업데이트
    if (keyword || category || optData2) {
      loadPage(searchCurrentPage, keyword, category, optData2);
    } else {
      loadFaq();
    }
    loadData();
  }, [
    searchCurrentPage,
    keyword,
    loadPage,
    category,
    optData2,
    loadData,
    loadFaq,
  ]);

  const hashSet = hash.filter((
    code, // console.log(code),
  ) => code.parentCode?.includes(String(category)));

  const CategorySearch = (val: string) => {
    // console.log(val);
  };

  return (
    <LayoutWithTitle location={location} title="FAQ">
      <SectionTop>
        <Container>
          <header className="flex-wrap">
            <div className="col-img">
              <Image pcSrc={TopImg} mobileSrc={TopImg} />
            </div>
            <div className="col-dec">
              <h2>
                <Tit size="s2">후원금은 투명하게 쓰이나요?</Tit>
              </h2>
              <p className="header-dec" id="faqSection">
                유니세프한국위원회는 고유목적사업을 위해 매년 총 수입의 90%
                이상을 국내외 어린이를 위해 사용합니다.
                <br />
                유니세프한국위원회는 내부감사와 외부회계법인 감사 및 유니세프
                본부 감사를 통해 후원금 사용의 적정성을 검증받고 있으며,
                국세청에 투명하게 공시하고 있습니다.
              </p>
            </div>
          </header>
        </Container>
      </SectionTop>

      <SectionFaq id="categorySection">
        <Container>
          <Row searchBar>
            <NewsBoardSearchBar
              initialKeyword={keyword}
              initialCategory={category}
              onSearch={(value) => {
                navigate(
                  `${currentPageInfo?.baseUri}?${queryString.stringify({
                    keyword: value,
                  })}#faqSection`,
                );
              }}
            />
          </Row>

          <PanelContainer>
            <ul className="UiTabPanel">
              <li>
                {loaded && (
                  <Link to="/faq#categorySection">
                    <TabButton
                      active={category === undefined}
                      outline
                      full
                      onClick={() => {
                        setOnCategory(``);
                      }}
                    >
                      전체
                    </TabButton>
                  </Link>
                )}
              </li>
              {cg &&
                cg?.map((name, idx) => (
                  <li key={name.indexNumber}>
                    <Link
                      to={`/faq?category=${name.code}#categorySection`}
                      key={name.code}
                    >
                      <TabButton
                        active={Number(category) === idx + 1}
                        // active={name.code === idx + 1}
                        outline
                        full
                        onClick={() => {
                          // setOnCategory(name.code);
                          // setOnHash(`${String(idx + 1)}01`);
                          CategorySearch(`${name.code}`);
                          setOnCategory(name.code);
                        }}
                      >
                        {name.title}
                      </TabButton>
                    </Link>
                  </li>
                ))}
            </ul>
          </PanelContainer>
          <TabContainer>
            <TabContents className="TabContents">
              <Content>
                {hashSet.length !== 0 && (
                  <TagBox>
                    {hashSet.map((hash, index) => (
                      <Link
                        to={`/faq?category=${category}&optData2=${hash.code}#categorySection`}
                        key={hash.code}
                      >
                        <HashButton
                          key={hash.code}
                          outline
                          shape="round"
                          size="sm"
                          active={hash.code === optData2}
                        >
                          #{hash.codeName}
                        </HashButton>
                      </Link>
                    ))}
                  </TagBox>
                )}
                {nodes.length !== 0 ? (
                  <>
                    <AccordionContainer initialKey={0}>
                      {nodes.map((row, index) => (
                        <AccordionItem
                          key={row.boardIndexNumber}
                          toggleKey={index}
                          renderToggle={(onClick) => (
                            <NavigationButton
                              onClick={onClick}
                              className="accordion-opener"
                            >
                              <dl>
                                <dt className="sm-cg-title">
                                  {cg[Number(row.optData1 - 1)]?.title}
                                </dt>
                                <dd>
                                  {row.optData3 !== null &&
                                  row.optData3 !== `` ? (
                                    <span className="sm-cg">
                                      [{row.optData3}]
                                    </span>
                                  ) : null}
                                  {row.subject}
                                </dd>
                              </dl>
                            </NavigationButton>
                          )}
                        >
                          <AccordionBody
                            dangerouslySetInnerHTML={{
                              __html:
                                row.contents &&
                                row.contents.replace(
                                  /href/g,
                                  `target='_blank' href`,
                                ),
                            }}
                          />
                        </AccordionItem>
                      ))}
                    </AccordionContainer>
                    <TagPagination
                      {...currentPageInfo}
                      anchor="#categorySection"
                    />
                  </>
                ) : (
                  <NoData>
                    <Tit size="s4-1" color="gray" className="dec">
                      검색 결과가 없습니다
                    </Tit>

                    <div className="question-wrap">
                      <dl>
                        <dt>
                          <Tit size="s3" color="sky">
                            찾으시는 답변이 없으신가요?
                          </Tit>
                        </dt>
                        <dd>
                          <ButtonMoreView
                            mode="sky"
                            onClick={() => {
                              popupStore.show(`로그인 후에 문의 가능합니다.`, {
                                onConfirm: () =>
                                  navigate(
                                    `/auth/login?redirectTo=/mypage/onetoone`,
                                  ),
                              });
                            }}
                          >
                            1:1 문의하기
                          </ButtonMoreView>
                        </dd>
                      </dl>
                    </div>
                  </NoData>
                )}
              </Content>
            </TabContents>
          </TabContainer>
        </Container>
      </SectionFaq>
    </LayoutWithTitle>
  );
};

export default Faq;
